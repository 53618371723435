export default {
  category: 'book',
  name: '177 Mental Toughness Secrets of the World Class',
  author: 'Steve Siebold',
  date: '10/1/24',
  path: '/notes/177-mental-toughness-secrets-of-the-world-class',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-177-mental-toughness-secrets-of-the-world-class-steve-siebold.jpg',
  tags: ['Self-help', 'Business', 'Leadership'],
  time: 143,
  rating: 9.1,
  review: '',
  link: 'https://www.amazon.com/Mental-Toughness-Secrets-World-Class/dp/097550035X/ref=pd_lpo_sccl_1/146-2036625-3558439?pd_rd_w=d77cF&content-id=amzn1.sym.4c8c52db-06f8-4e42-8e56-912796f2ea6c&pf_rd_p=4c8c52db-06f8-4e42-8e56-912796f2ea6c&pf_rd_r=QJCYTBP95ZQNR683R47M&pd_rd_wg=hJ02R&pd_rd_r=425411e1-3791-48b7-bacb-36aa63879a50&pd_rd_i=097550035X&psc=1',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/66e9772543c1e08dcc5711fb',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
