export default {
  category: 'book',
  name: 'No More Mr Nice Guy',
  author: 'Robert Glover',
  date: '9/17/24',
  path: '/notes/no-more-mr-nice-guy',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-no-more-mr-nice-guy-robert-glover.jpg',
  tags: ['Self-help', 'Relationships'],
  time: 44,
  rating: 7.6,
  review: '',
  link: 'https://www.amazon.com/No-More-Mr-Nice-Guy/dp/0762415339',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/66db0728bcb3b204e6c75161',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
