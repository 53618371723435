export default {
  category: 'book',
  name: 'Outwitting the Devil',
  author: 'Napoleon Hill',
  date: '10/2/24',
  path: '/notes/outwitting-the-devil',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-outwitting-the-devil-napoleon-hill.jpg',
  tags: ['Self-help'],
  time: 24,
  rating: 7.6,
  review: '',
  link: 'https://www.amazon.com/Outwitting-Devil-Secret-Freedom-Success/dp/1454900679',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/66db0785bcb3b204e6c75167',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
